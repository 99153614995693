<template>

  <div class="">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">
            #
          </th>
          <th scope="col">
            Users
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
        >
          <th scope="row"> {{ index }}</th>
          <td>
            <span v-for="(users,i) in item" :key="i"> <span class="badge badge-warning mr-1">{{ users.user_name }}</span></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

// Use toast

import {
  DUPLICATE_DEVICES,
} from '@core/services/api'

export default {
  data() {
    return {
      isp_id: JSON.parse(localStorage.getItem('userData')),
      items: [],
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + DUPLICATE_DEVICES, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then(res => {
          console.log('data:', res.data.data.items)
          this.items = res.data.data.items
        })
    },

  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

</style>
  <style scoped>
  .error-message {
    color: red;
  }
  </style>
